{
    "230005610": { "metadata": { "name": "RESCUE LION" } },
    "230012240": { "metadata": { "name": "RESCUE AKTIA PORVOO" } },
    "230018000": { "metadata": { "name": "TURVA" } },
    "230018360": { "metadata": { "name": "RESCUE KOTKA" } },
    "230039440": { "metadata": { "name": "RESCUE LASSI" } },
    "230051150": { "metadata": { "name": "RESCUE REPA" } },
    "230051160": { "metadata": { "name": "RESCUE LAHITAPIOLAVS" } },
    "230051170": { "metadata": { "name": "RESCUE EMMI" } },
    "230059740": { "metadata": { "name": "RESCUE AKTIA" } },
    "230059750": { "metadata": { "name": "RESCUE OP OULU" } },
    "230059760": { "metadata": { "name": "RESCUE RANTAMO" } },
    "230061400": { "metadata": { "name": "RESCUE BJOERKSKAER" } },
    "230111580": { "metadata": { "name": "NIPROMEC RESCUE" } },
    "230118650": { "metadata": { "name": "RESCUE TEIPPARI" } },
    "230157573": { "metadata": { "name": "RESCUE PV 322" } },
    "230936760": { "metadata": { "name": "TARGA RESCUE" } },
    "230937480": { "metadata": { "name": "RESCUE ABSO" } },
    "230939100": { "metadata": { "name": "RESCUE RAUTAUOMA" } },
    "230983250": { "metadata": { "name": "RESCUE AD MARE" } },
    "230983340": { "metadata": { "name": "RESCUE JANNE MALEN" } },
    "230987580": { "metadata": { "name": "RESCUE PYORRE" } },
    "230988240": { "metadata": { "name": "WARTSILA RESCUE" } },
    "230993590": { "metadata": { "name": "RESCUE HAILIKARI" } },
    "230994270": { "metadata": { "name": "RESCUE JENNY WIHURI" } },
    "230994810": { "metadata": { "name": "RESCUE DEGERBY" } },
    "230995040": { "metadata": { "name": "RESCUE FAGERO" } },
    "230112160": { "metadata": { "name": "SAR WILLIMIES" } },
    "230108280": { "metadata": { "name": "OTTO MALM" } },
    "276672000": { "metadata": { "name": "STAR" } },
    "276829000": { "metadata": { "name": "MEGASTAR" } }
}